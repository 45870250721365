/**
 * 自己定义的模块
 */
var storage={
    set(key,value){
        localStorage.setItem(key,value);
    },
    get(key){
        return localStorage.getItem(key);
    },remove(key){
        localStorage.removeItem(key)
    },GetUserInfo()
    {
      return JSON.parse(this.get('userInfo'));
    },hasPermission(perCode)
    {
        let userInfo =this.GetUserInfo();
        let hasPermission = userInfo.role.indexOf(perCode);
        return hasPermission;
    },GetNavigation()
    {
      return JSON.parse(this.get('navigation'));
    },loginout()
    {
        let userinfo = this.GetUserInfo();
        //alert(userinfo.logintype);
        if (userinfo.logintype === 0) {
            this.remove("token");
            this.remove("refresh_token");
            this.remove("userInfo");
            this.remove("navigation");
            window.location.href = "/cuhk";
        } else {
            this.remove("token");
            this.remove("refresh_token");
            this.remove("userInfo");
            this.remove("navigation");
            window.location.href = "/ngo";
        }
    }
};
// 暴露出去，供外部使用
export default storage;
