import { memberState } from "../states/member";
import { LOAD_MEMBERS } from "../actions/member";
export const memberReducers = (state = memberState, action) => {
    switch (action.type) {
        case LOAD_MEMBERS:
            return {
                ...state,
                memebers: action.members
            };
    }
    return state;
}